// Google Font Import
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:300,400,600,700|Open+Sans:400,400i,700');

// Body
body{
	font-family: $primary-font;
	-webkit-font-smoothing: antialiased;
}

// Headings
h1,h2,h3,h4,h5,h6{
	font-family: $primary-font;
	color: $title-color;
	font-weight:400;
	line-height: 1;
}

h1{
	font-size: 60px;
}

h2{
	font-size: 48px;
}

h3{
	font-size: 36px;
}

h4{
	font-size: 30px;
}

h5{
	font-size: 20px;
}

h6{
	font-size: 16px;
}
// Others 
p, li, blockquote, label{
	font-size: 14px;
	letter-spacing: 0;
	line-height: 22px;
	color: $text-color;
	margin-bottom: 0;
}
p{
	line-height: 27px;
}

.lead{
	font-size: 1.5rem;
	line-height: 1.5;
}
cite{
	font-size: 14px;
	font-style: normal;
}

// Placeholder Color Change
.form-control::-webkit-input-placeholder { 
	color: $text-color; 
	font-size: 16px;
}
